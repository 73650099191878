import React, { useEffect, useMemo } from 'react'
import arrayShuffle from 'array-shuffle'
import dynamic from 'next/dynamic'

import { useBreakpoint } from '~/lib/viewport'
import TestimonialCard from '~/components/TestimonialCard/TestimonialCard'
import { TestimonialCardColor } from '~/components/TestimonialCard/types'
import { getReactKeyForTestimonial, Testimonial, TestimonialVariant } from '~/lib/testimonials/types'

export const FIRST_PAGE = 0
export const TESTIMONIALS_PER_PAGE_MAP: Record<string, number> = {
  sm: 2,
  md: 3,
  lg: 6,
  // lg: 99999999,
}

const COLORS: TestimonialCardColor[] = ['green', 'blue', 'pink']

const LAYOUT = [
  'md:mt-6 lg:mt-9',
  'md:mt-12 lg:mt-0 lg:ml-6',
  'md:mt-0 lg:mt-9 lg:ml-6',
  'lg:mt-6',
  'lg:mt-10 lg:mr-10',
  'lg:mt-2 lg:mr-14',
]

type Props = {
  page?: number
  perPageMap?: Record<string, number>
  setPage?: (page: number) => void
  items: Testimonial[]
  variant: TestimonialVariant
}

export default dynamic(
  // <https://linear.app/pie/issue/DEV-431/fix-other-persons-tweet-text-shown>
  () => Promise.resolve(TestimonialCardList),
  { ssr: false }
)

// eslint-disable-next-line @typescript-eslint/ban-types
const TestimonialCardList = ({
  page = FIRST_PAGE,
  setPage: setPage = () => null,
  perPageMap = TESTIMONIALS_PER_PAGE_MAP,
  items,
  variant,
}: Props) => {
  const currentBreakpoint = useBreakpoint()
  items = useMemo(() => arrayShuffle(items), [items])
  const perPage = perPageMap[currentBreakpoint] || perPageMap.lg
  const totalPages = Math.ceil(items.length / perPage)
  page = page % totalPages // start over when exhausted
  const pageStart = page * perPage
  const itemsToShow = getItemsForDisplay({ items, pageStart, perPage })
  const pageSize = itemsToShow.length
  const layout = generateLayout(pageSize)
  const colors = generateColors({ page, pageSize: pageSize })
  useEffect(() => setPage(FIRST_PAGE), [currentBreakpoint, setPage])
  return (
    <div className='grid grid-cols-1 md:grid-cols-3 gap-x-10 gap-y-12'>
      {itemsToShow.map((item, i) => (
        <div key={getReactKeyForTestimonial(variant, item)} className={layout[i]}>
          <TestimonialCard color={colors[i]} variant={variant} testimonial={item} />
        </div>
      ))}
    </div>
  )
}

const generateColors = ({ page, pageSize }: { page: number; pageSize: number }): TestimonialCardColor[] => {
  // Page to page: rotate 1st-row colors to the left by one (GBP -> BPG).
  const pageShift = page % COLORS.length
  let baseRow = [...COLORS.slice(pageShift), ...COLORS.slice(0, pageShift)]
  let colors = [...baseRow]
  while (colors.length < pageSize) {
    // Row to row: rotate colors to the right by one (GBP -> PGB).
    const extraRow = [baseRow[baseRow.length - 1], ...baseRow.slice(0, -1)]
    colors = [...colors, ...extraRow]
    baseRow = extraRow
  }
  return colors
}

const generateLayout = (pageSize: number) => {
  let layout = [...LAYOUT]
  while (layout.length < pageSize) {
    layout = [...layout, ...LAYOUT]
  }
  return layout
}

const getItemsForDisplay = ({
  items,
  pageStart,
  perPage,
}: {
  items: Testimonial[]
  pageStart: number
  perPage: number
}): Testimonial[] => {
  let selected = items.slice(pageStart, pageStart + perPage)
  if (selected.length < perPage) {
    // Make it show a full page by borrowing items from the start.
    selected = [...selected, ...items.slice(0, perPage - selected.length)]
  }
  selected = sortForDisplay(selected)
  return selected
}

const sortForDisplay = (items: Testimonial[]): Testimonial[] => {
  let sorted = [...items]
  if (sorted.length > 1) {
    const byLength = [...sorted]
    byLength.sort((a, b) => b.text.length - a.text.length)
    // Longest to 2nd position
    sorted = moveToPosition(sorted, byLength[0], 1)
    if (sorted.length > 3) {
      // 2nd longest to 2nd last position
      sorted = moveToPosition(sorted, byLength[1], sorted.length - 2)
    }
  }
  return sorted
}

const moveToPosition = (items: Testimonial[], item: Testimonial, index: number) => {
  const others = items.filter((it) => it != item)
  return [...others.slice(0, index), item, ...others.slice(index)]
}
