import { LinkHref } from '~/components/Link/types'

export interface RawTweet {
  avatar: string
  text: string
  url: LinkHref
  slug?: string
}

export interface Tweet extends RawTweet {
  username: string // includes `@`
  // `body` becomes a HTML string here
}

export interface Endorsement {
  avatar: string
  text: string
  name: string
  role: string
}

export type Testimonial = Tweet | Endorsement

export enum TestimonialVariant {
  TWEET = 'tweet',
  ENDORSEMENT = 'endorsement'
}

export const getReactKeyForTestimonial = (variant: TestimonialVariant, t: Testimonial) =>
  variant == TestimonialVariant.TWEET ? (t as Tweet).url : (t as Endorsement).name
