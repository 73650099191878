import {escapeHtml} from '~/lib/html'
import {TWITTER_ROOT_URL} from '~/constants'

import {RAW_TWEETS} from './tweets-raw'
import {RawTweet, Tweet} from './types'


export const getTweetBySlug = (slug: string) => {
  const tweet = TWEETS.find((tweet)=> tweet.slug == slug)
  if (!tweet) {throw new Error(`No such tweet: slug=${slug}`)}
  return tweet
}

const cleanTweets = (tweets: RawTweet[]) => tweets.map(cleanTweet)
const cleanTweet = (tweet: RawTweet): Tweet =>
  ({
    ...tweet,
    username: `@${  getUsername(tweet.url)}`,
    text: emboldenHashtags(escapeHtml(tweet.text)),
  })
const getUsername = (url: string) => url.replace(`${TWITTER_ROOT_URL}/`, '').split('/')[0]
const emboldenHashtags = (body: string)=> body.replace(/(^|\s)(\B#\w+)(\s|$)/gi, '$1<b>$2</b>$3')

export const TWEETS: Tweet[] = cleanTweets(RAW_TWEETS)
