import Image from 'next/legacy/image'
import clsx from 'clsx'
import { useRef } from 'react'

import Text from '~/components/Text/Text'
import Link from '~/components/Link/Link'
import { Endorsement, TestimonialVariant, Tweet } from '~/lib/testimonials/types'

import { TestimonialCardProps } from './types'
import styles from './TestimonialCard.module.sass'
import useRandomRotate from './useRandomRotate'

const TweetFooter = (tweet: Tweet) => (
  <Text variant='caption' fontSize={[12, 10, 12]} className={clsx('tweet-text', styles.text)}>
    <Link href={tweet.url} external data-track={`external:open-embedded-tweet:@${tweet.url.split('/')[3]}`}>
      {tweet.username}
    </Link>
  </Text>
)

const EndorsementFooter = (endorsement: Endorsement) => (
  <>
    <Text as='div' fontSize={15} className={clsx('tweet-name', styles.text, styles.name)}>
      {endorsement.name}
    </Text>
    <Text variant='mono' as='div' fontSize={12} className={clsx('tweet-text', styles.text)}>
      {endorsement.role}
    </Text>
  </>
)

const TestimonialCard: React.FC<TestimonialCardProps> = (props) => {
  const { className, color = 'green', variant, testimonial, hoverable = true } = props
  const tweetRef = useRef<HTMLDivElement | null>(null)
  useRandomRotate(tweetRef, { isHoverable: hoverable })
  return (
    <div className={clsx(styles.tweet, styles[color], hoverable && styles.hoverable, className)} ref={tweetRef}>
      <div className={styles.author}>
        <Image
          src={testimonial.avatar}
          width={50}
          height={50}
          layout='responsive'
          className={clsx(styles.avatar, styles[color])}
        />
      </div>
      <Text
        fontSize={[16, 12, 15]}
        className={clsx(styles.content, styles.text)}
        as='div'
        dangerouslySetInnerHTML={{ __html: testimonial.text }}
      />
      {variant == TestimonialVariant.TWEET ? (
        <TweetFooter {...(testimonial as Tweet)} />
      ) : (
        <EndorsementFooter {...(testimonial as Endorsement)} />
      )}
    </div>
  )
}

export default TestimonialCard
