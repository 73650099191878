import { RawTweet } from './types'

export const RAW_TWEETS: RawTweet[] = [
  {
    url: 'https://twitter.com/Extrawurst/status/1344987196772798466',
    avatar: 'https://pbs.twimg.com/profile_images/973327871828688901/zMuku2SG_400x400.jpg',
    text: `
      I just discovered HTTPie for myself - why did I use curl instead of this all these years? :D
      https://httpie.io
    `,
  },
  {
    url: 'https://twitter.com/copyconstruct/status/761080549448359936',
    avatar: 'https://pbs.twimg.com/profile_images/1347304619118981120/OyPjIuLz_400x400.jpg',
    text: `
      OMG httpie is love at first use.
      It'll be hard for me to give up curl (as I've been using it forever) but will happily ditch it for httpie.
    `,
  },
  {
    url: 'https://twitter.com/hahwul/status/1333237786858594306',
    avatar: 'https://pbs.twimg.com/profile_images/1300420444844032000/1Vn3jy9m_400x400.jpg',
    text: `
      It's sad to know this good tool late. Anyway, it's really useful and cool! #httpie
    `,
  },
  {
    url: 'https://twitter.com/newsycombinator/status/656485501403340800',
    avatar: 'https://pbs.twimg.com/profile_images/1534251337122295809/NGX0RlOw_400x400.jpg',
    text: `
      Httpie: A CLI http client that will make you smile
      https://radek.io/2015/10/20/httpie/
    `,
  },
  {
    url: 'https://twitter.com/therealztarbug/status/1354540154426372099',
    avatar: 'https://pbs.twimg.com/profile_images/1105188695236329472/YFqcLGKD_400x400.png',
    text: `
      "apt install httpie" gives you a really fancy #cli tool to browse web #APIs. More details at https://httpie.io
      #FOSS rulez!
    `,
  },
  {
    url: 'https://twitter.com/abhaybhargav/status/1043762188035522560',
    avatar: 'https://pbs.twimg.com/profile_images/1505463414600003587/HIsZ5rhj_400x400.jpg',
    text: `
      Not kidding, but I think I literally saved 10 hours this week using #HTTPie instead of #curl.
      If you are still on curl, you gotta learn how to use httpie.
      Its intutitive AF and so damn easy to use and extend #winning
    `,
  },
  {
    slug: 'github-blog',
    url: 'https://twitter.com/github/status/1083447651910254592',
    avatar: 'https://pbs.twimg.com/profile_images/1414990564408262661/r6YemvF9_400x400.jpg',
    text: `
      If you're in need of a great CLI tool to use with HTTP servers, RESTful APIs, and web services you're in luck.
      HTTPie from
      @jakubroztocil
       is the perfect solution 🤗
      Part of December's #GHReleaseRadar
      https://httpie.org
    `,
  },
  {
    url: 'https://twitter.com/sid_thinketh/status/1134896479791931392',
    avatar: 'https://pbs.twimg.com/profile_images/1426170440645369856/6YaycDfe_400x400.jpg',
    text: `
      I don't know why I didn't use HTTPie till now.  Such an elegant tool for anything Http.  Kudos.

    https://httpie.org

    #http #httpie
    `,
  },
  {
    slug: 'holy-crap',
    url: 'https://twitter.com/girasquid/status/186971260977283073',
    avatar: 'https://pbs.twimg.com/profile_images/786975494952525825/JoXVR2R2_400x400.jpg',
    text: `
      Holy crap, https://github.com/jkbr/httpie has changed my workflow almost as much as switching from notepad did.
    `,
  },
  {
    url: 'https://twitter.com/webology/status/849342966485446656',
    avatar: 'https://pbs.twimg.com/profile_images/907277477935964161/ZSxasCvw_400x400.jpg',
    text: `
      I can't believe I haven't used HTTPie https://httpie.org sooner. Especially given how many APIs I have created / worked on.
    `,
  },
  {
    url: 'https://twitter.com/igrigorik/status/176501056937799680',
    avatar: 'https://pbs.twimg.com/profile_images/1438014409419358210/0MI540vK_400x400.jpg',
    text: `
      I think I'm in love.. httpie @ http://bit.ly/zT6zM3 -
      "http GET http://igvita.com" in your console + pygments, etc!
    `,
  },
  {
    url: 'https://twitter.com/KADAMJAYV/status/1142155762531106816',
    avatar: 'https://pbs.twimg.com/profile_images/1407719579304951809/1bfpuZn1_400x400.jpg',
    text: `
      #httpie
      I have to talk to external #API a lot which is painful to use,
      #httpie is super human friendly and helps #bootstrapping or testing calls to unknown endpoints.
    `,
  },
  {
    url: 'https://twitter.com/ianfoo/status/1236061190402371589',
    avatar: 'https://pbs.twimg.com/profile_images/1246986441596358656/F0zCEtWs_400x400.jpg',
    text: `
      HTTPie (@clihttp) has more or less replaced curl for me. <3
    `,
  },
  {
    url: 'https://twitter.com/worldofprasanna/status/949817365222445056',
    avatar: 'https://pbs.twimg.com/profile_images/715199593554829313/qq0_VI6b_400x400.jpg',
    text: `
      Never thought file upload would be as simple as this,

      http POST :8080/events < events.json

      #httpie #awesommee
    `,
  },
  {
    url: 'https://twitter.com/royclarkson/status/850004726896787456',
    avatar: 'https://pbs.twimg.com/profile_images/1099487562945708032/3Wsm9Kux_400x400.jpg',
    text: `
      I’ve probably mentioned @clihttp before, but it’s built-in JSON support alone makes it worth using.
      https://httpie.org
    `,
  },
  {
    url: 'https://twitter.com/n1k0/status/562997625184858112',
    avatar: 'https://pbs.twimg.com/profile_images/1219293148326825985/HJJQAxbJ_400x400.jpg',
    text: `
      blown away by httpie, great CLI for consuming/testing REST apis https://github.com/jakubroztocil/httpie
    `,
  },
  {
    url: 'https://twitter.com/patio11/status/664470958590156800',
    avatar: 'https://pbs.twimg.com/profile_images/1346314816827232256/zCQCJW6N_400x400.jpg',
    text: `
      httpie ( https://github.com/jkbrzt/httpie ) is a command-line curl-like utility which is just a joy to use. Syntax coloring and wonderful UX for APIs
    `,
  },
  {
    url: 'https://twitter.com/zwattic/status/838999724120121345',
    avatar: 'https://pbs.twimg.com/profile_images/1536029682805616640/kpJ_bryn_400x400.jpg',
    text: `
      Absolutely in love! #httpie https://httpie.org via @clihttp
    `,
  },
  {
    url: 'https://twitter.com/flatpooks/status/905191886339616768',
    avatar: 'https://pbs.twimg.com/profile_images/1272491284087832578/445E3Q2h_400x400.png',
    text: `
      huge thanks for #httpie - the best http client - end of :)
    `,
  },
  {
    slug: 'love-httpie-thats-all',
    url: 'https://twitter.com/bowshears/status/558106493741195266',
    avatar: 'https://pbs.twimg.com/profile_images/671556792543920128/EsnmiI-i_400x400.jpg',
    text: `
      I love httpie. That's all.
    `,
  },
]
