import {TWITTER_ROOT_URL, TWITTER_COMPANY_USERNAME} from '~/constants'

type TweetShareParams = { url: string, text: string, via?: string, related?: string[] }

export const makeShareTweetUrl = ({url, text, via=TWITTER_COMPANY_USERNAME, related=[]}: TweetShareParams)=> {
  // <https://developer.twitter.com/en/docs/twitter-for-websites/tweet-button/guides/web-intent>
  const params = new URLSearchParams()
  params.set('text', text)
  params.set('via', via)
  params.set('url', url)
  params.set('related', related.join(','))
  return `${TWITTER_ROOT_URL}/intent/tweet?${params}`
}

export const makeTwitterFollowUrl = (username: string)=>
  // <https://developer.twitter.com/en/docs/twitter-for-websites/tweet-button/guides/web-intent>
  `${TWITTER_ROOT_URL}/intent/follow?screen_name=${username}`

export const makeTwitterProfileUrl = (username: string) =>
  `${TWITTER_ROOT_URL}/${username}`
