import { Author } from '~/content/blog/types'

export const AUTHORS: Record<string, Author> = {
  'claudia': {
    name: 'Cláudia Delgado',
    twitter: 'claudiatd',
    avatar: '/Images/Team/Claudia.jpg',
    team: true,
  },
  'jakub': {
    name: 'Jakub Roztocil',
    twitter: 'jkbrzt',
    avatar: '/Images/Team/Jakub.jpg',
    team: true,
  },

  'mickael': {
    name: 'Mickaël Schoentgen',
    twitter: '__tiger222__',
    avatar: '/Images/Authors/Mickael.jpg',
    team: false,
  },
  'adam-duvander': {
    name: 'Adam DuVander',
    twitter: 'adamd',
    avatar: '/Images/Authors/adam-duvander.jpg',
    team: false,
  },
  'dj-walker-morgan': {
    name: 'DJ Walker-Morgan',
    twitter: 'codepope',
    avatar: '/Images/Authors/dj-walker-morgan.png',
    team: false,
  },
  'ivan': {
    name: 'Ivan Mironchik',
    twitter: 'aiven715',
    avatar: '/Images/Authors/IvanM.jpg',
    team: true,
  },
}

export const TEAM_AUTHORS: Author[] = Object.values(AUTHORS).filter((author) => author.team)
