import React from 'react'

import Text from '~/components/Text/Text'
import CompanyLogo from '~/components/CompanyLogo/CompanyLogo'
import { CompanyIcon } from '~/components/CompanyLogo/types'

import styles from './Companies.module.css'

const Companies: React.FC = () => {
  // Currently, we only support these
  const companyNames: CompanyIcon[] = [
    'amazon',
    'apple',
    'facebook',
    'google',
    'twitter',
    'linkedin',
    'spotify',
  ]

  return (
    <div className={styles.wrapper}>
      <div className={styles.textSection}>
        <Text variant='subheading' fontSize={[28, 28, 40]} className={styles.subheading}>
          Trusted by the best
        </Text>
        <Text variant='body' fontSize={[18, 14, 18]} className={styles.companiesBody}>
          Developers all around the world rely on HTTPie for API testing. Our users include
          the engineers behind some of the most important technology companies driving change today.
        </Text>
      </div>
      <div>
        <div className={styles.iconSection}>
          {companyNames.map((name) => (
            <CompanyLogo name={name} key={name} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Companies
