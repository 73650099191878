import React, { useState } from 'react'
import clsx from 'clsx'

import Button from '~/components/Button/Button'
import Text from '~/components/Text/Text'
import TestimonialCardList, { FIRST_PAGE } from '~/components/TestimonialCardList/TestimonialCardList'
import { HOMEPAGE_FULL_URL, TWITTER_COMPANY_USERNAME } from '~/constants'
import { makeShareTweetUrl, makeTwitterFollowUrl } from '~/lib/twitter'
import { TEAM_AUTHORS } from '~/content/blog/authors'
import { TWEETS } from '~/lib/testimonials/tweets'
import { TestimonialVariant } from '~/lib/testimonials/types'

import styles from './Tweets.module.css'

const TweetSection: React.FC = () => {
  const [page, setPage] = useState(FIRST_PAGE)
  const handleMoreLove = () => {
    setPage(page + 1)
    document.getElementById('tweets')?.scrollIntoView({ behavior: 'smooth' })
  }

  const tweetUrl = makeShareTweetUrl({
    text: 'HTTPie: cross-platform API testing client for humans',
    url: HOMEPAGE_FULL_URL,
    related: TEAM_AUTHORS.map((author) => author.twitter),
  })

  return (
    <div className='site-container' id='tweets'>
      <Text variant='subheading' fontSize={[28, 28, 40]} className={styles.subheading}>
        Loved by the community
      </Text>
      <TestimonialCardList page={page} setPage={setPage} items={TWEETS} variant={TestimonialVariant.TWEET} />
      <div
        className={clsx(
          styles.buttonGroup,
          'grid space-y-6 w-2/2 sm:flex sm:flex-wrap justify-center sm:space-y-0 sm:space-x-6'
        )}
      >
        <Button icon='reload' onClick={handleMoreLove} variant='secondary' data-track='cta:show-more-twitter-love'>
          Show more love
        </Button>
        <Button variant='share' icon='twitter' external={true} link={tweetUrl} data-track='cta:share-on-twitter'>
          Share on Twitter
        </Button>
        <Button
          variant='share'
          icon='twitter'
          external={true}
          link={makeTwitterFollowUrl(TWITTER_COMPANY_USERNAME)}
          data-track='cta:follow-on-twitter'
        >
          Follow @{TWITTER_COMPANY_USERNAME}
        </Button>
      </div>
    </div>
  )
}

export default TweetSection
