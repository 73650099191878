import { RefObject, useEffect, useMemo } from 'react'

import { useBreakpoint } from '~/lib/viewport'

const getRandomAngle = () => {
  const POSSIBLE_ANGLES = [-3, -2, -1, 1, 2, 3]
  const randomAngle = POSSIBLE_ANGLES[Math.floor(Math.random() * POSSIBLE_ANGLES.length)]
  return randomAngle
}

interface UseRotateOptions {
  isHoverable?: boolean
}

export default function useRandomRotate<T extends HTMLElement | null = HTMLElement>(
  ref: RefObject<T>,
  { isHoverable = true }: UseRotateOptions = {}
) {
  const randomRotateValue = useMemo(() => getRandomAngle(), [])
  const currentBreakpoint = useBreakpoint()

  useEffect(() => {
    const target = ref.current
    const isDesktop = ['lg', 'xl'].includes(currentBreakpoint)

    if (!target || !isDesktop) {
      return
    }

    const rotateHoveredElement = (event: MouseEvent, value: number) => {
      event.stopPropagation()
      const element = event.currentTarget as HTMLElement | null
      element?.style.setProperty('--tw-rotate', `${value.toString()}deg`)
    }

    const rotateListener = (event: MouseEvent) => rotateHoveredElement(event, randomRotateValue)
    const straightenListener = (event: MouseEvent) => rotateHoveredElement(event, 0)

    if (isHoverable) {
      target.addEventListener('mouseover', rotateListener)
      target.addEventListener('mouseout', straightenListener)
    }

    return () => {
      target.removeEventListener('mouseover', rotateListener)
      target.removeEventListener('mouseout', straightenListener)
    }
  }, [isHoverable, randomRotateValue, ref, currentBreakpoint])
}
