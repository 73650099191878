import React from 'react'

import Icon from '~/components/Icon/Icon'

import { Props } from './types'
import styles from './CompanyLogo.module.css'

const CompanyLogo: React.FC<Props> = ({ name }) => {
  const logo = (
    <div className={styles.iconWrapper}>
      <div className={styles[name]}>
        <Icon name={name} />
      </div>
    </div>
  )

  return logo
}

export default CompanyLogo
